import React from "react";
import DefaultLayout from "../layouts";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import * as styles from "./../styles/home.module.scss";
import RoundedButton from "../components/sub-components/roundedButton"

const IndexPage = ({ data }) => {
  const loanOfficersList = data.directus.loanOfficer;
  const imageSrc = data.directus.header?.logo?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;

  const loanofficerdata = loanOfficersList.map(officer => {
    if (officer.domainUserName) {
      const userData = officer.domainUserName.map(name => {
        console.log(officer.legacyUrl)
        return { [name.username]: officer.legacyUrl }
      })

      if (officer.legacyUrl !== undefined) {
        return userData;
      }
    }
  })

  const finalData = loanofficerdata.filter(item => {
    return item !== undefined
  })
  console.log(finalData)

  return (
    <DefaultLayout>
      <Seo
        title="Loan Officers"
        description="Find loan officers from Novus Home Mortgage"
        image={imageSrc} />
      <main>
        <section className={styles.maincontent}>
          <div className={styles.containerhome}>
            {loanOfficersList.map((officer, index) => (
              <div className={styles.loanOfficerListItem} key={index}>
                {officer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src !== undefined && (
                  <div className={styles.loanOfficerImg}>
                    <img src={officer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${officer.firstName} ${officer.lastName}`} />
                  </div>
                )}
                {officer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src === undefined && (
                  <div className={styles.loanOfficerNoImgPlaceholder}>

                  </div>
                )}
                <div className={styles.loanOfficerDetails}>
                  <div className={styles.loanOfficerNMLS}>
                    <strong>{officer.title ? officer.title : "Loan Officer"} • NMLS # <span>{officer.nmlsNumber}</span></strong>
                  </div>
                  <div className={styles.name}>
                    <span>{officer.firstName}</span> <span>{officer.lastName}</span>
                  </div>
                  <div className={styles.contact}>
                    <a href={`tel:${officer.phoneNumber}`}>
                      <div>{officer.phoneNumber.replace("-", ".")}</div>
                    </a>
                    <a href={`mailto:${officer.email}`}>
                      <div>{officer.email}</div>
                    </a>
                  </div>
                  <div className={styles.address}>
                    <div>{officer.branch.addressLineOne}</div>
                    <div>{officer.branch.city}, {officer.branch.state} {officer.branch.postalCode}</div>
                  </div>
                </div>
                <div className={styles.loanOfficerLinks}>
                  <div className={styles.bg1}>
                    <RoundedButton color="greenToBlue" text={`${officer.firstName}'s page`} destination={`/${officer.lastName.toLowerCase()}-${officer.firstName.toLowerCase()}`} />
                  </div>
                  <div className={styles.bg2}>
                    <RoundedButton color="greenToBlue" text={`Apply with ${officer.firstName}`} destination={officer.applyLink} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </DefaultLayout>
  );
};

export const query = graphql`
query loanOfficers {
  directus {
    loanOfficer(filter: {status: {_eq: "published"}}) {
      id
      firstName
      lastName
      title
      phoneNumber
      email
      nmlsNumber
      useLegacyUrl
      externalDomain
      domainUserName
      legacyUrl
      applyLink
      image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      branch {
        name
        addressLineOne
        addressLineTwo
        city
        state
        postalCode
      }
    }
    header {
      logo {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        description
      }
    }
  }
}
`;

export default IndexPage;
